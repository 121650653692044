import { useContext, useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { Button, Paper } from "@mui/material";
import { CSVLink } from "react-csv";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import CancelIcon from "@mui/icons-material/Cancel";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import Alert from "@mui/material/Alert";
import { FormTextField } from "./form/FormTextField";
import { AppContext } from "../context/AppContext";
import {
    AccountingSettings,
    TimeTrackingPeriod,
    TimeTrackingRecord,
} from "../interfaces";
import { PageLoader } from "./Loader";
import { currencyFormatter } from "../helpers/accounting";
import { formatName, formatNameAlphabetically } from "../helpers/candidate";
import { AxiosError } from "axios";
import { handleError } from "../helpers/error";
import { JobType } from "../helpers/job";

export const TimeTrackingHoursList = () => {
    const { timeTrackingPeriodId } = useParams();
    const { getApi } = useContext(AppContext);
    const [loading, setLoading] = useState<boolean>(false);
    const [timeTrackingPeriod, setTimeTrackingPeriod] =
        useState<TimeTrackingPeriod | null>(null);
    const [outdatedRecords, setOutdatedRecords] = useState<
        TimeTrackingRecord[] | null
    >(null);
    const [accountingSettings, setAccountingSettings] =
        useState<AccountingSettings | null>(null);
    const [openOtModal, setOpenOtModal] = useState<boolean>(false);
    const csvRef = useRef<
        CSVLink & HTMLAnchorElement & { link: HTMLAnchorElement }
    >(null);

    const modalStyle = {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: 400,
        bgcolor: "background.paper",
        p: 4,
    };

    const checkOutdatedRecords = (period: TimeTrackingPeriod) => {
        const periodStart = new Date(period.startDate);
        const outdated = period?.records?.filter((r: TimeTrackingRecord) => {
            const { placement } = r;
            const endDate = placement.endDate
                ? new Date(placement.endDate)
                : null;
            return endDate && r.active && endDate < periodStart;
        });
        setOutdatedRecords(outdated ?? null);
    };

    const fetchData = async () => {
        setLoading(true);
        try {
            const api = await getApi();
            const response = await api.get(
                `/time-tracking/${timeTrackingPeriodId}`,
                { params: { records: true } },
            );
            setTimeTrackingPeriod(response.data as TimeTrackingPeriod);
            checkOutdatedRecords(response.data as TimeTrackingPeriod);
        } catch (e) {
            handleError(e as AxiosError);
        }
        setLoading(false);
    };

    const fetchAccountingSettings = async () => {
        const api = await getApi();
        try {
            const response = await api.get("/accounting/settings");
            const settings = response.data as AccountingSettings;
            setAccountingSettings(settings);
        } catch (e) {
            handleError(e as AxiosError);
        }
    };

    useEffect(() => {
        fetchData();
        fetchAccountingSettings();
    }, []);

    const submitForm = async () => {
        const confirmed = confirm(
            "Are you sure you want to save this time tracking period?",
        );
        if (!confirmed) return;

        setLoading(true);
        try {
            const api = await getApi();
            const response = await api.put(
                `/time-tracking/${timeTrackingPeriodId}`,
                timeTrackingPeriod,
            );
            setTimeTrackingPeriod(response.data as TimeTrackingPeriod);
            checkOutdatedRecords(response.data as TimeTrackingPeriod);
        } catch (e) {
            handleError(e as AxiosError);
        }
        setLoading(false);
    };

    const calculateRow = (
        field: keyof TimeTrackingRecord,
        value: number | null | undefined,
        index: number,
    ) => {
        if (!timeTrackingPeriod?.records) return;

        const newRow = { ...timeTrackingPeriod?.records[index] };

        if (field == "hours") {
            newRow.hours = !value || isNaN(value) ? 0 : value;
            if (newRow.hours != 0) {
                newRow.hours = Math.max(newRow.hours, 0);
                newRow.hours = Math.min(newRow.hours, 40);
            }
        } else if (field == "otHours") {
            newRow.otHours = !value || isNaN(value) ? 0 : value;
            if (newRow.otHours != 0) {
                newRow.otHours = Math.max(newRow.otHours, 0);
                if (newRow.otHours > 20) {
                    setOpenOtModal(true);
                }
            }
        }

        newRow.totalPay =
            Math.round(newRow.hours * (newRow?.payRate ?? 0) * 100) / 100;
        newRow.totalBill =
            Math.round(newRow.hours * (newRow?.billRate ?? 0) * 100) / 100;
        newRow.totalOtPay =
            Math.round(newRow.otHours * (newRow?.otPayRate ?? 0) * 100) / 100;
        newRow.totalOtBill =
            Math.round(newRow.otHours * (newRow?.otBillRate ?? 0) * 100) / 100;

        const newPeriod = { ...timeTrackingPeriod };
        newPeriod.records = [...timeTrackingPeriod.records];
        newPeriod.records[index] = newRow;
        setTimeTrackingPeriod(newPeriod);
    };

    const deleteRow = (row: TimeTrackingRecord, index: number) => {
        if (!timeTrackingPeriod?.records) return;
        const confirmed = confirm(
            `Are you sure you want to remove ${formatName(
                row.placement.candidate,
            )}`,
        );
        if (confirmed) {
            const newPeriod = { ...timeTrackingPeriod };
            newPeriod.records = [...timeTrackingPeriod.records];
            const newRow = { ...timeTrackingPeriod?.records[index] };
            newRow.active = false;
            newPeriod.records[index] = newRow;

            setTimeTrackingPeriod(newPeriod);
        }
    };

    const rowMapper = (row: TimeTrackingRecord, i: number) => {
        return row.active ? (
            <TableRow
                key={row.id}
                sx={{
                    "&:last-child td, &:last-child th": { border: 0 },
                }}
            >
                <TableCell align="center">
                    <CancelIcon
                        className="hover-pointer"
                        onClick={() => deleteRow(row, i)}
                    ></CancelIcon>
                </TableCell>
                <TableCell align="center">
                    {formatNameAlphabetically(row.placement.candidate)}
                </TableCell>
                <TableCell align="center">
                    {row.placement.candidate.fileId}
                </TableCell>
                <TableCell align="center" style={{ width: "100px" }}>
                    <FormTextField
                        type="number"
                        value={row.hours === 0 ? null : row.hours}
                        variant="standard"
                        onChange={(e) =>
                            calculateRow("hours", parseFloat(e.target.value), i)
                        }
                        disabled={row.placement.job.type === JobType.permanent}
                    />
                </TableCell>
                <TableCell align="center">
                    {currencyFormatter(row.payRate ?? 0)}
                </TableCell>
                <TableCell align="center">
                    {currencyFormatter(row.totalPay)}
                </TableCell>
                <TableCell align="center">
                    {currencyFormatter(row.billRate ?? 0)}
                </TableCell>
                <TableCell align="center">
                    {currencyFormatter(row.totalBill)}
                </TableCell>
                <TableCell align="center" style={{ width: "100px" }}>
                    <FormTextField
                        type="number"
                        value={row.otHours === 0 ? null : row.otHours}
                        variant="standard"
                        onChange={(e) =>
                            calculateRow(
                                "otHours",
                                parseFloat(e.target.value),
                                i,
                            )
                        }
                        disabled={row.placement.job.type === JobType.permanent}
                    />
                </TableCell>
                <TableCell align="center">
                    {currencyFormatter(row.otPayRate ?? 0)}
                </TableCell>
                <TableCell align="center">
                    {currencyFormatter(row.totalOtPay)}
                </TableCell>
                <TableCell align="center">
                    {currencyFormatter(row.otBillRate ?? 0)}
                </TableCell>
                <TableCell align="center">
                    {currencyFormatter(row.totalOtBill)}
                </TableCell>
                <TableCell align="center">
                    {currencyFormatter(row.totalDirectFee ?? 0)}
                </TableCell>
            </TableRow>
        ) : (
            <></>
        );
    };

    const csvHeaders: string[] = [
        "CO Code",
        "Batch ID",
        "Name",
        "File #",
        "Reg Hours",
        "OT Hours",
    ];

    const formatCsvRow = (record: TimeTrackingRecord) => {
        return [
            accountingSettings?.coCode,
            "",
            formatNameAlphabetically(record.placement.candidate),
            record.placement.candidate.fileId,
            record.hours,
            record.otHours,
        ];
    };

    const downloadCsv = () => {
        csvRef?.current?.link.click();
    };

    return (
        <>
            <h1>Time Tracking</h1>
            {timeTrackingPeriod && <h3>{timeTrackingPeriod.description}</h3>}
            <Button
                color="primary"
                variant="contained"
                type="submit"
                onClick={submitForm}
                disabled={loading}
            >
                Save
            </Button>
            {!loading && (
                <>
                    {" "}
                    <Button
                        color="primary"
                        variant="contained"
                        type="submit"
                        onClick={() => downloadCsv()}
                    >
                        Create Upload File
                        <CSVLink
                            data={
                                timeTrackingPeriod?.records
                                    ?.filter((r) => r.active)
                                    .map(formatCsvRow) ?? []
                            }
                            headers={csvHeaders}
                            filename={`SynergyATS Time Tracking - ${timeTrackingPeriod?.description}`}
                            ref={csvRef}
                            className="hidden"
                        ></CSVLink>
                    </Button>
                </>
            )}
            {loading && (
                <div>
                    <PageLoader />
                </div>
            )}
            {!loading && outdatedRecords && (
                <div className="vertical-margin">
                    {outdatedRecords.map(
                        (record: TimeTrackingRecord, i: number) => {
                            return (
                                <Alert
                                    severity="error"
                                    key={`outdated-record-${i}`}
                                >
                                    Outdated record for{" "}
                                    {formatNameAlphabetically(
                                        record.placement.candidate,
                                    )}
                                    . Termination Date:{" "}
                                    {record.placement.endDate}
                                </Alert>
                            );
                        },
                    )}
                </div>
            )}
            {!loading && (
                <TableContainer component={Paper} className="spreadsheet-table">
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell align="center">Remove</TableCell>
                                <TableCell align="center">Name</TableCell>
                                <TableCell align="center">File ID</TableCell>
                                <TableCell align="center">Hours</TableCell>
                                <TableCell align="center">Pay Rate</TableCell>
                                <TableCell align="center">Total Pay</TableCell>
                                <TableCell align="center">Bill Rate</TableCell>
                                <TableCell align="center">Total Bill</TableCell>
                                <TableCell align="center">OT Hours</TableCell>
                                <TableCell align="center">
                                    OT Pay Rate
                                </TableCell>
                                <TableCell align="center">
                                    Total OT Pay
                                </TableCell>
                                <TableCell align="center">
                                    OT Bill Rate
                                </TableCell>
                                <TableCell align="center">
                                    Total OT Bill
                                </TableCell>
                                <TableCell align="center">Direct Fee</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {timeTrackingPeriod?.records?.map(rowMapper)}
                        </TableBody>
                    </Table>
                </TableContainer>
            )}
            <Modal
                open={openOtModal}
                onClose={() => setOpenOtModal(false)}
                aria-labelledby="ot-hours-modal"
                aria-describedby="ot-hours-modal-over-20"
            >
                <Box sx={modalStyle}>
                    <Typography
                        id="modal-modal-title"
                        variant="h6"
                        component="h2"
                    >
                        {"OT hours greater than 20"}
                    </Typography>
                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                        Notice: the overtime hours are greater than 20. Press
                        escape or click outside this notice to close.
                    </Typography>
                </Box>
            </Modal>
        </>
    );
};
